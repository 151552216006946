<template>
    <div
        :id="title"
        class="bg-dark min-h-480px w-full flex gap-24px px-120px ipad:(min-h-483px flex-col px-24px gap-16px py-48px items-center) mobile:(min-h-656px flex-col px-0 gap-0 py-32px)"
    >
        <div
            class="w-690px mt-98.5px ipad:(w-full mt-0) mobile:(w-full flex flex-col mt-0 items-center justify-center)"
        >
            <div
                class="section_headline text-light ipad:(flex justify-center) mobile:(leading-48px text-base-40px text-center flex justify-center)"
            >
                <span class="ligne">{{ title }}</span>
            </div>
            <div
                class="desc text-light mt-40px mb-98.5px ipad:(text-center w- mb-48px mt-30px) mobile:(text-center mb-48px mx-16px)"
            >
                <p class="pb-20px">{{ about.desc1 }}</p>
                <p>{{ about.desc2 }}</p>
            </div>
        </div>
        <img class="scale_img ipad:hidden mobile:hidden" src="../../../img/about_L.svg" />
        <img
            class="hidden ipad:(block w- h-full) mobile:(h-full w-full)"
            src="../../../img/about_M.svg"
        />
        <img class="hidden mobile:(block h-full w-full)" src="../../../img/about_S.svg" />
    </div>
</template>
<script>
export default {
    name: 'about',
    props: {
        about: {
            type: Object,
            default: () => {}
        },
        title: String
    },
    data() {
        return {}
    }
}
</script>
<style></style>
